import React, { useState } from 'react';
import { Button, Heading, P } from '@dnb/eufemia';
import { CXMSection } from 'components/Grid';
import { CXM_PAGE_LOADING_STATE } from 'utils/constants';
import { signIn } from '../AuthenticatedRoute';
import { LoginWrapper } from './styles';

export const LoginModule = () => {
  const [isUserSignedIn, setIsSignedIn] = useState(false);

  if (isUserSignedIn) {
    <div>{CXM_PAGE_LOADING_STATE}</div>;
  }
  return (
    <CXMSection>
      <LoginWrapper>
        <Heading size="x-large" top="medium" bottom="0">
          Innlogging for rådgivere!
        </Heading>
        <P>Rådgivere blir logget inn via Azure Active Directory.</P>

        <Button
          text={'Logg inn'}
          variant="primary"
          onClick={async () => {
            const isSignedIn = await signIn();
            isSignedIn === true && setIsSignedIn(isSignedIn);
          }}
        ></Button>
      </LoginWrapper>
    </CXMSection>
  );
};
