import React from 'react';
import MainLayout from  'components/layout/MainLayout';
import LoginModule from 'components/LoginModule';
import { FluidContainer } from 'components/Grid';

const darkTheme = {
  backgroundColor: 'var(--color-ocean-green)',
  color: 'var(--color-white)',
  logoColor: 'var(--color-white)',
  linkColor:'var(--color-white)'
}
const LoginPage = () =>{
  return(
    <MainLayout theme={darkTheme}>
      <FluidContainer>   
         <LoginModule/>
     </FluidContainer>
    </MainLayout>
  )
}
export default LoginPage;